<template>
	<v-container fluid>
		<v-row align="center">
			<DateRangeSelector @selected="newDateRange" default="last90days" />
			<div class="pl-4 grey--text">{{dateRangeText}}</div>
		</v-row>
		<v-row>
			<v-col
					v-for="(o, i) in options"
					:key="i"
					xs="12"
					sm="12"
					md="6"
					xl="4"
			>
				<v-card>
					<v-card-text>
						<Highchart :options="o" :updateArgs="[true, true]" />
						<div v-if="o.loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
							<Loader :show-text="false" />
						</div>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col
				v-for="(metric, index) in metrics"
				xs="12"
				sm="12"
				md="4"
				xl="4"
				:key="index"
			>
				<KeyMetrics
					:title="metric.title"
					:sub-title="metric.subTitle"
					:goal="metric.goal"
					:value="metric.value"
					:dateRangeName="metric.dateRangeName"
					:metricName="index"
					@changed="getGoals"
					:forecast="metric.forecast"
					color="blue lighten-3"
				/>
			</v-col>
		</v-row>


	</v-container>
</template>

<script>
	import DateRangeSelector from '@/components/material/DateRangeSelector'
	import Loader from "@/components/core/Loader"
	import Highchart from '@/components/material/Highchart'
	import KeyMetrics from '@/components/material/KeyMetrics'

	export default {
		components: {
			DateRangeSelector,
			Loader,
			Highchart,
			KeyMetrics,
		},
		data () {
			return {
				options: {
					dailyEngagement: {},
					totalEngagement: {},
					dailyContentCreators: {},
					dailyActiveUsers: {},
					dailyAgrees: {},
					socialRegistration: {},
					utmRegistration: {},
					platformRegistration: {},
					dailyPosts: {},
					dailyComments: {},
					dailyShares: {},
					//dailyNewUsersByPlatform: {},
					//dailyNewUsersByApplication: {},
					dailyNewUsers: {},
				},
				metrics: {
					totalContentCreators: {
						title: `Total Content creators`,
						subTitle: `Created at least one post`,
						value: null,
						goal: null,
						forecast: null,
					},
					totalActiveWebUsers: {
						title: `Active Web Users`,
						subTitle: ``,
						value: null,
						goal: null,
						forecast: null,
					},
					totalActiveAppUsers: {
						title: `Active App Users`,
						subTitle: `Only works with max 2 year span`,
						value: null,
						goal: null,
						forecast: null,
					},
				},
				dateRange: ``,
				dateRangeParams: ``,
				dateRangeName: ``,
				dateRangeText: ``,
				flags: [],
			}
		},
		methods: {
			getGoals() {
				this.$httpInt.get(`/statistics/goals/`)
					.then(res => {
						for (const [key] of Object.entries(this.metrics)) {
							this.metrics[key].goal = res.data[key] && res.data[key][this.dateRangeName] || null
						}
					})
			},
			setTimeRange(timerange) {
				if (!timerange) return
				this.dateRangeText = `${this.dayjs(timerange.start).format('D MMM YYYY')} - ${this.dayjs(timerange.end).add(-1,'day').format('D MMM YYYY')}`
			},
			getDailyEngagement() {
				this.options.dailyEngagement = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily Engagement`
					},
					subtitle: {
						text: `Agrees + Posts + Comments + Shares`
					},
					series: []
				}

				this.$httpInt.get(`/statistics/daily-engagement/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyEngagement.series = [{
							data: res.data.data && res.data.data[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.dailyEngagement.loading = false)
			},
			getDailyContentCreators() {
				this.options.dailyContentCreators = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily Content Creators`
					},
					subtitle: {
						text: `All type of Posts`
					},
					series: []
				}

				this.$httpInt.get(`/v2/statistics/daily-creators/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyContentCreators.series = [{
							data: res.data.result && res.data.result[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.dailyContentCreators.loading = false)
			},

			getTotalEngagement() {
				this.options.totalEngagement = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `North Star Metric`
					},
					subtitle: {
						text: `Agrees + Posts + Comments + Shares`
					},
					series: []
				}

				this.$httpInt.get(`/statistics/daily-engagement/?aggregate=true&${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.totalEngagement.series = [{
							data: res.data.data && res.data.data[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.totalEngagement.loading = false)
			},
			getDailyComments() {
				this.options.dailyComments = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily Comments`
					},
					series: []
				}

				this.$httpInt.get(`/statistics/daily-comments/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyComments.series = [{
							data: res.data.data && res.data.data[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.dailyComments.loading = false)
			},
			getFlags() {
				this.getApi(`${process.env.VUE_APP_API_URL}/api/statistics/chart-flags?${this.dateRangeParams}`)
					.then(res => this.flags = res.data)
			},
			getDailyShares() {
				this.options.dailyShares = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily Shares`
					},
					series: []
				}

				this.$httpInt.get(`/statistics/daily-shares/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyShares.series = [{
							data: res.data.data && res.data.data[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.dailyShares.loading = false)
			},
			getDailyPosts() {
				this.options.dailyPosts = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily Posts`
					},
					series: []
				}

				this.$httpInt.get(`/statistics/daily-posts/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyPosts.series = [{
							data: res.data.data && res.data.data[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.dailyPosts.loading = false)
			},
			getDailyNewUsersByPlatform() {
				this.options.dailyNewUsersByPlatform = {
					loading: true,
					chart: {
						type: `area`
					},
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily New Users by Platform`
					},
					subtitle: {
						text: `Private accounts only`
					},
					plotOptions: {
						area: {
							stacking: `normal`,
							connectNulls: true,
							lineWidth: 0,
							marker: {
								lineWidth: 0,
							}
						}
					},
					series: []
				}

				if (this.dateRangeName === `allHistory`) {
					this.options.dailyNewUsersByPlatform.loading = false
					return
				}

				this.$httpInt.get(`/statistics/daily-new-users-by-platform/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyNewUsersByPlatform.series = [...res.data.data, ...this.flags]
					})
					.finally(() => this.options.dailyNewUsersByPlatform.loading = false)
			},
			getDailyNewUsersByApplication() {
				this.options.dailyNewUsersByApplication = {
					loading: true,
					chart: {
						type: `area`
					},
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily New Users by Application`
					},
					subtitle: {
						text: `Private accounts only`
					},
					plotOptions: {
						area: {
							stacking: `normal`,
							connectNulls: true,
							lineWidth: 0,
							marker: {
								lineWidth: 0,
							}
						}
					},
					series: []
				}

				if (this.dateRangeName === `allHistory`) {
					this.options.dailyNewUsersByApplication.loading = false
					return
				}

				this.$httpInt.get(`/statistics/daily-new-users-by-application/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyNewUsersByApplication.series = [...res.data.data, ...this.flags]
					})
					.finally(() => this.options.dailyNewUsersByApplication.loading = false)
			},
			getDailyNewUsers() {
				this.options.dailyNewUsers = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily New Members`
					},
					subtitle: {
						text: `Private accounts only`
					},
					series: []
				}

				this.$httpInt.get(`/statistics/daily-new-users/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyNewUsers.series = [{
							data: res.data.data && res.data.data[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.dailyNewUsers.loading = false)
			},
			getDailyAgree() {
				this.options.dailyAgrees = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily Agrees`
					},
					series: []
				}

				this.$httpInt.get(`/v2/statistics/daily-agrees/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyAgrees.series = [{
							data: res.data.result && res.data.result[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.dailyAgrees.loading = false)
			},
			getDailyActiveUsers() {
				this.options.dailyActiveUsers = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily Active Members`
					},
					subtitle: {
						text: `Any activity from users`
					},
					series: []
				}

				this.$httpInt.get(`/statistics/daily-active-users/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						this.options.dailyActiveUsers.series = [{
							data: res.data.data && res.data.data[0] || [],
							name: `Per day`,
						}, ...this.flags]
					})
					.finally(() => this.options.dailyActiveUsers.loading = false)
			},
			getSocialRegistration() {
				this.options.socialRegistration = {
					loading: true,
					credits: {
						enabled: false,
					},
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: `pie`
					},
					title: {
						text: `Users register methods`
					},
					tooltip: {
						pointFormat: 'Percent: <b>{point.percentage:.1f}%</b><br>Total: <b>{point.y}</b>'
					},

					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: `pointer`,
							dataLabels: {
								enabled: true,
								format: '<b>{point.name}</b>: {point.percentage:.1f} %'
							}
						}
					},
					accessibility: {
						point: {
							valueSuffix: `%`
						}
					},
					series: [{
						name: `Method`,
						colorByPoint: true,
						data: []
					}]
				}

				this.$httpInt.get(`/statistics/total-private-profiles/?forecast=true&${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						if (res.data.methods) {
							Object.entries(res.data.methods).forEach(k => {
								this.options.socialRegistration.series[0].data.push({
									name: k[0],
									y: k[1]
								})
							})
						}
					})
					.finally(() => this.options.socialRegistration.loading = false)
			},
			getNewProfiles() {
				this.options.utmRegistration = {
					loading: true,
					credits: {
						enabled: false,
					},
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: `pie`
					},
					title: {
						text: `UTM Registrations`
					},
					tooltip: {
						pointFormat: 'Percent: <b>{point.percentage:.1f}%</b><br>Total: <b>{point.y}</b>'
					},

					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: `pointer`,
							dataLabels: {
								enabled: true,
								format: '<b>{point.name}</b>: {point.percentage:.1f} %'
							}
						}
					},
					accessibility: {
						point: {
							valueSuffix: `%`
						}
					},
					series: [{
						name: `Method`,
						colorByPoint: true,
						data: []
					}]
				}

				this.options.platformRegistration = {
					loading: true,
					credits: {
						enabled: false,
					},
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: `pie`
					},
					title: {
						text: `Platform Registrations`
					},
					tooltip: {
						pointFormat: 'Percent: <b>{point.percentage:.1f}%</b><br>Total: <b>{point.y}</b>'
					},

					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: `pointer`,
							dataLabels: {
								enabled: true,
								format: '<b>{point.name}</b>: {point.percentage:.1f} %'
							}
						}
					},
					accessibility: {
						point: {
							valueSuffix: `%`
						}
					},
					series: [{
						name: `Method`,
						colorByPoint: true,
						data: []
					}]
				}

				this.$httpInt.get(`/statistics/total-new-profiles/?${this.dateRangeParams}`)
					.then(res => {
						this.setTimeRange(res.data.timerange)
						if (res.data.subcount) {
							this.options.utmRegistration.series[0].data.push({ name: 'With UTM', y: res.data.subcount.with_utm })
							this.options.utmRegistration.series[0].data.push({ name: 'Without UTM', y: res.data.subcount.without_utm })

							this.options.platformRegistration.series[0].data.push({ name: 'App', y: res.data.subcount.by_app })
							this.options.platformRegistration.series[0].data.push({ name: 'Web', y: res.data.subcount.by_web })
						}
					})
					.finally(() => {
						this.options.utmRegistration.loading = false
						this.options.platformRegistration.loading = false
					})
			},
			refetch_all_data () {
				//this.getFlags()
				this.getDailyEngagement()
				this.getDailyContentCreators()
				this.getTotalEngagement()
				this.getDailyComments()
				this.getDailyShares()
				this.getDailyAgree()
				this.getDailyActiveUsers()
				this.getDailyNewUsers()
				//this.getDailyNewUsersByPlatform()
				//this.getDailyNewUsersByApplication()
				this.getDailyPosts()
				this.getSocialRegistration()
				this.getNewProfiles()

				this.$httpInt.get(`/statistics/total-creators/?forecast=true&${this.dateRangeParams}`)
					.then(res => {
						this.metrics.totalContentCreators.value = res.data.count
						this.metrics.totalContentCreators.forecast = res.data.forecast
					})

				this.$httpInt.get(`/v2/statistics/ga/?metrics=users&timerange=${this.dateRange}`)
					.then(res => {
						this.metrics.totalActiveWebUsers.value = res.data.result[0].value
					})

				this.$httpInt.get(`/v2/statistics/amplitude/?metrics=users&e={"event_type":"_all"}&m=uniques&s=[{"prop":"platform","op":"is","values":["iOS","Android"]}]&timerange=${this.dateRange}`)
					.then(res => {
						this.metrics.totalActiveAppUsers.value = res.data.result.count
					})

				this.getGoals()
			},
			newDateRange(o) {
				this.reset()
				this.dateRange = o.date_range
				this.dateRangeParams = o.params
				this.dateRangeName = o.name
				this.refetch_all_data()
			},
			reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
		},
		name: `StatisticsDetailedMetrics`
	}

</script>
